<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">人员管理</h2>
        <div class="RightDiv">
          <div class="firDiv">
            <el-select v-model="meetRole" clearable placeholder="请选择会议角色" @change="roleChange(meetRole)">
              <el-option v-for="item in conferenceOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="firDiv">
            <el-input v-model="meetName" clearable placeholder="请输入名称" @keyup.enter.native="searchName" @blur="searchName" @clear="searchName"></el-input>
          </div>
          <div class="firDiv">
            <el-input v-model="meetPhone" clearable placeholder="请输入手机号" @keyup.enter.native="searchPhone" @blur="searchPhone" @clear="searchPhone"></el-input>
          </div>
          <div class="firDiv">
            <el-button type="primary" @click="depaddKeshi">添加工作人员</el-button>
          </div>
        </div>
        <el-table :data="tableData" border center>
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column prop="nickName" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="gender" label="性别" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="手机号" align="center">
          </el-table-column>
          <el-table-column prop="email" label="邮箱" align="center">
          </el-table-column>

          <el-table-column prop="conferenceRoleId" label="会议角色" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.conferenceRoleId=='2'">会议管理员</div>
              <div v-else-if="scope.row.conferenceRoleId=='3'">分会场管理员</div>
              <div v-else-if="scope.row.conferenceRoleId=='4'">任务负责人</div>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" width="280">
            <template style="display: flex" slot-scope="scope">
              <el-button :disabled="scope.row.conferenceRoleId=='2'" size="mini" type="primary" @click="editManaMeet(scope.row)"><i class="el-icon-edit"></i></el-button>
              <el-button :disabled="scope.row.conferenceRoleId=='2'" size="mini" type="danger" @click="delManMeet(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
        </el-pagination>
      </el-main>
    </el-container>
    <el-dialog :title="addName" :visible.sync="AdddialogVisible" width="430px" top="1vh" :before-close="AdddialogVisibleClose">
      <el-form :model="AddDateModel" ref="AddDateRef" :rules="AddDateRules" label-width="130px">

        <el-form-item label="手机号：" prop="phone">
          <el-input v-model="AddDateModel.phone" @blur="phonFocus" placeholder="请填写手机号"></el-input>
        </el-form-item>

        <el-form-item label="姓名：" prop="nickName">
          <el-input v-model="AddDateModel.nickName" placeholder="请填写姓名"></el-input>
        </el-form-item>

        <el-form-item label="邮箱：" prop="email">
          <el-input v-model="AddDateModel.email" placeholder="请填写邮箱"></el-input>
        </el-form-item>

        <el-form-item label="性别：" prop="gender">
          <el-radio-group v-model="AddDateModel.gender">
            <el-radio label="男">男</el-radio>
            <el-radio label="女">女</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="会议角色：" prop="conferenceRoleId">
<!--         3：会议管理员 4：任务负责人-->
          <el-select v-model="AddDateModel.conferenceRoleId" @input="change()" placeholder="请选择会议角色" @change="conferenceChange(AddDateModel.conferenceRoleId)">
            <el-option v-for="item in conferenceOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="depar-dia btnCen">
          <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing" @click="parmen">{{btnText}}</el-button>
          <el-button v-else type="info" :loading="addDepClickKing"></el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import _qs from "qs";
export default {
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value && String(value) !== "0") {
        return callback(new Error("不能为空"));
      }
      setTimeout(() => {
        if (!/^1[3|4|5|7|8][0-9]\d{8}$/.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }, 100);
    };
    var checkEmail = (rule, value, cb) => {
      // 验证邮箱的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
      if (regEmail.test(value)) {
        //合法的邮箱
        return cb()
      }
      cb(new Error('请输入合法的邮箱'))
    }
    return {
      value: true,
      novalue: false,
      btnText: "添加",
      addName: "添加工作人员",
      reayName: "",
      addDepClick: true,
      addDepClickKing: false,
      AddDateModel: {
        userId:'',
        nickName:'',
        phone:"",
        email:"",
        gender:"",
        conferenceRoleId:"",
      },
      AddDateRules: {
        nickName: [
          { required: true, message: "请填写姓名", trigger: "blur" },
        ],
        phone: [
          { required: true,validator: checkAge,trigger: "blur" },
        ],
        email: [
          { required: false}
        ],
        gender: [
          { required: true, message: "请选择性别", trigger: "change" },
        ],
        conferenceRoleId: [
          { required: true, message: "请选择会议角色", trigger: "change" },
        ],
      },
      AdddialogVisible: false,
      // 当前页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 总数
      total: 1,
      // 表格数据
      tableData: [],
      perId:'',
      // 2：会议管理员 3：分会场管理员 4：任务负责人
      conferenceOptions:[
        {name:'分会场管理员',id:3},
        {name:'任务负责人',id:4},
      ],
      meetRole:"",
      meetName:"",
      meetPhone:""
    };
  },
  created() {
    this.staffPageList();
  },
  methods: {
    searchName(){
      this.pageNum = 1;
      this.staffPageList();
    },
    searchPhone(){
      this.pageNum = 1;
      this.staffPageList();
    },
    roleChange(val){
      this.pageNum = 1;
      this.meetRole=val
      this.staffPageList();
    },
    async phonFocus(){
      let data = _qs.stringify({
        conferenceId:window.sessionStorage.getItem('conferenceId'),
        phone:this.AddDateModel.phone
      });
      let { data: res } = await this.$axios.getStaffByPhone(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        // console.log(res.data)
        if(res.data.data){
          // this.AddDateModel=res.data.data
          this.AddDateModel = JSON.parse(JSON.stringify(res.data.data));
          this.AddDateModel.userId = res.data.data.userId;
          if(res.data.data.conferenceRoleId){
            this.AddDateModel.conferenceRoleId=Number(res.data.data.conferenceRoleId)
          }
        }else{
          this.AddDateModel.userId=''
        }
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    conferenceChange(val){
      this.AddDateModel.conferenceRoleId=val
    },
    change() {
      this.$forceUpdate();
    },
    async staffPageList() {
      let data = {
        page: this.pageNum, //页数
        size: this.pageSize, //每页显示条数
        conferenceId:window.sessionStorage.getItem('conferenceId'),//名称
        conferenceRoleId:this.meetRole,
        nickName:this.meetName,
        phone:this.meetPhone
      };
      let { data: res } = await this.$axios.staffPageList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
    // 科室管理分页
    handleCurrentChange(val) {
      this.pageNum = val;
      // console.log(`当前页: ${val}`);
      this.staffPageList();
    },
    // 点击添加科室
    depaddKeshi() {
      var venueRole = window.sessionStorage.getItem("venueRole");
      console.log(venueRole)
      if(venueRole === '2' || venueRole === '3'){
        this.conferenceOptions = [
          {name:'分会场管理员',id:3},
          {name:'任务负责人',id:4}
        ]
      }
      else{
        this.conferenceOptions = [
          {name:'任务负责人',id:4}
        ]
      }

      this.AdddialogVisible = true;
    },
    // 点击添加科室的时候取消正则
    AdddialogVisibleClose() {
      this.addName = "添加工作人员";
      this.btnText = "添加";
      this.reayName = "";
      this.AdddialogVisible = false;
      this.$refs.AddDateRef.resetFields();
      this.AddDateModel = {
        userId:'',
        nickName:'',
        phone:"",
        email:"",
        gender:"",
        conferenceRoleId:"",
      };
    },
    parmen() {
      if (this.btnText == "添加") {
        this.addAtten();
      } else if (this.btnText == "修改") {
        this.editMeet();
      }
    },
    async addAttenMans(data) {
      let res = await this.$axios.addStaff(data);
      // console.log(res);
      this.addDepClick = true;
      this.addDepClickKing = false;
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 200) {
        this.AdddialogVisibleClose();
        this.staffPageList();
        this.$message({
          message: res.data.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
    },
    addAtten() {
      this.$refs.AddDateRef.validate((valid) => {
        if (valid) {
          this.addDepClick = false;
          this.addDepClickKing = true;
          let data
          if(this.AddDateModel.userId){
            data = _qs.stringify({
              conferenceId:window.sessionStorage.getItem('conferenceId'),//会议id
              nickName:this.AddDateModel.nickName,//用户真实姓名
              phone:this.AddDateModel.phone,//手机号
              email:this.AddDateModel.email,//邮箱
              gender:this.AddDateModel.gender,//性别
              conferenceRoleId:this.AddDateModel.conferenceRoleId,//会议角色
              userId:this.AddDateModel.userId
            });
          }else{
            data = _qs.stringify({
              conferenceId:window.sessionStorage.getItem('conferenceId'),//会议id
              nickName:this.AddDateModel.nickName,//用户真实姓名
              phone:this.AddDateModel.phone,//手机号
              email:this.AddDateModel.email,//邮箱
              gender:this.AddDateModel.gender,//性别
              conferenceRoleId:this.AddDateModel.conferenceRoleId,//会议角色
            });
          }

          this.addAttenMans(data);
        }
      });
    },
    // 点击操作删除
    async delManMeet(row) {
      // console.log(row)
      const confirmResult = await this.$confirm("确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);

      if (confirmResult !== "confirm") {
        return this.$message.info("已经取消删除");
      }
      let data = _qs.stringify({
        conferenceId:window.sessionStorage.getItem('conferenceId'),
        userId: row.userId,
      });
      let { data: res } = await this.$axios.deleteStaff(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.staffPageList();
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    editManaMeet(row) {
      // console.log(row)
      this.addName = "修改工作人员";
      this.btnText = "修改";
      this.perId = row.userId;

      var venueRole = window.sessionStorage.getItem("venueRole");
      if(venueRole === '2' || venueRole === '3'){
        this.conferenceOptions = [
          {name:'分会场管理员',id:3},
          {name:'任务负责人',id:4}
        ]
      }
      else{
        this.conferenceOptions = [
          {name:'任务负责人',id:4}
        ]
      }


      this.AddDateModel = JSON.parse(JSON.stringify(row));
      this.AddDateModel.conferenceRoleId=Number(row.conferenceRoleId)
      this.AdddialogVisible = true;
    },
    // 点击修改科室用户管理的添加
    editMeet() {
      this.$refs.AddDateRef.validate(async (valid) => {
        if (!valid) return;
        this.addDepClick = false;
        this.addDepClickKing = true;
        let data = _qs.stringify({
          userId:this.perId,
          conferenceId:window.sessionStorage.getItem('conferenceId'),//会议id
          nickName:this.AddDateModel.nickName,//用户真实姓名
          phone:this.AddDateModel.phone,//手机号
          email:this.AddDateModel.email,//邮箱
          gender:this.AddDateModel.gender,//性别
          conferenceRoleId:this.AddDateModel.conferenceRoleId,//会议角色
        });
        let res = await this.$axios.updateStaff(data);
        // console.log(res);
        this.addDepClick = true;
        this.addDepClickKing = false;
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.status == 200) {
          this.AdddialogVisibleClose();
          this.AddDateModel = {
            name: "",
          };
          this.staffPageList();
          this.$message({
            message: res.data.msg,
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },


  },
};
</script>

<style lang="less" scoped>
/deep/ .el-button.el-button--primary.is-disabled{
  background-color: #a0cfff!important;
  border-color: #EBEEF5!important;
}
/deep/ .el-dialog__body {
  text-align: inherit;
  .btnCen {
    button {
      width: 150px;
    }
  }
}

.RightDiv{
  float: right;
  margin-bottom: 20px;
  display: flex;
  .firDiv{
    margin-left: 10px;
  }
}

.el-pagination{
  margin-top: 20px;
  text-align: center;
}

/deep/ .el-input__inner{
  width: 200px;
}
</style>
